import shaka from 'shaka-player';

window.addEventListener('DOMContentLoaded', async () => {
  shaka.polyfill.installAll();

  const sectionMultiview = document.querySelector('#multiview');
  const buttonFullScreen =
    document.querySelector<HTMLButtonElement>('#btn-fullscreen');
  const drmVideo = document.querySelector('#vod2livedrm');
  const drmPlayer = new shaka.Player(drmVideo);
  drmPlayer.configure({
    drm: {
      servers: {
        'com.widevine.alpha': 'https://widevine-dash.ezdrm.com/proxy?pX=1D331C'
      }
    }
  });

  try {
    await drmPlayer.load(
      'https://drmdemo.vc.eyevinn.technology/channels/playlist/master.m3u8'
    );
  } catch (e) {
    console.error(e);
  }

  buttonFullScreen?.addEventListener('click', async () => {
    sectionMultiview?.requestFullscreen();
  });
});
